/* eslint-disable */
import React from "react";
// @ts-ignore
import Image from "next/image"
// @ts-ignore
import roundedLogo from "../../../assets/coin.png";
import { SvgProps } from "../types";
import Logo from "./Logo";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
// @ts-ignore
  return <img alt="" src={"/assets/coin.png"} {...props} width={30} />;
};

export default Icon;