import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | PantherAi Swap',
  defaultTitle: 'PantherAi Swap',
  description: 'Trade, earn, and own crypto on the all-in-one',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@paiswap',
    site: '@paiswap',
  },
  openGraph: {
    title: "paiswap - Everyone's Favorite PAI",
    description: 'Trade, earn, and own crypto on the all-in-one',
    images: [{ url: 'https://i.postimg.cc/VLBmFVnz/OGimg.png' }],
  },
}
