/* eslint-disable */

import React from "react";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
// @ts-ignore
    <img src={"/assets/mobile-logo.png"} {...props}  alt=""/>
  );
};

export default Icon;