/* eslint-disable */
import React from "react";
// @ts-ignore
import Image from "next/image"
// @ts-ignore
import logo from "../../../assets/logo.png";
import { SvgProps } from "../types";


const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    // @ts-ignore
    <img alt="" src={"/assets/logo.png"} {...props} />
  );
};

export default Logo;