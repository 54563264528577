import memoize from 'lodash/memoize'
import { ContextApi } from '@pancakeswap/localization'
import { PageMeta } from './types'
import { ASSET_CDN } from './endpoints'

export const DEFAULT_META: PageMeta = {
  title: 'PAISWAP',
  description:
    'The most popular AMM on BSC by user count! Earn PAI through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PAISWAP), NFTs, and more, on a platform you can trust.',
  image: ``,
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string; image?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { title: t('Home') },
      '/swap': { basePath: true, title: t('Exchange'), image: `` },
      '/limit-orders': { basePath: true, title: t('Limit Orders'), image: `` },
      '/add': { basePath: true, title: t('Add Liquidity'), image: `` },
      '/remove': { basePath: true, title: t('Remove Liquidity'), image: `` },
      '/liquidity': { title: t('Liquidity'), image: `` },
      '/find': { title: t('Import Pool') },
      '/competition': { title: t('Trading Battle') },
      '/prediction': { title: t('Prediction'), image: `` },
      '/prediction/leaderboard': { title: t('Leaderboard'), image: `` },
      '/farms': { title: t('Farms'), image: `` },
      '/farms/auction': { title: t('Farm Auctions'), image: `` },
      '/pools': { title: t('Pools'), image: `` },
      '/lottery': { title: t('Lottery'), image: `` },
      '/ifo': { title: t('Initial Farm Offering'), image: `` },
      '/teams': { basePath: true, title: t('Leaderboard'), image: `` },
      '/voting': { basePath: true, title: t('Voting'), image: `` },
      '/voting/proposal': { title: t('Proposals'), image: `` },
      '/voting/proposal/create': { title: t('Make a Proposal'), image: `` },
      '/info': {
        title: `${t('Overview')} - ${t('Info')}`,
        description: 'View statistics for Pancakeswap exchanges.',
        image: ``,
      },
      '/info/pairs': {
        title: `${t('Pairs')} - ${t('Info')}`,
        description: 'View statistics for Pancakeswap exchanges.',
        image: ``,
      },
      '/info/tokens': {
        title: `${t('Tokens')} - ${t('Info')}`,
        description: 'View statistics for Pancakeswap exchanges.',
        image: ``,
      },
      '/nfts': { title: t('NFT Marketplace'), image: `` },
      '/nfts/collections': { basePath: true, title: t('Collections'), image: `` },
      '/nfts/activity': { title: t('Activity'), image: `` },
      '/profile': { basePath: true, title: t('Profile') },
      '/pancake-squad': { basePath: true, title: t('Pancake Squad') },
      '/pottery': { basePath: true, title: t('Pottery'), image: `` },
    },
    defaultTitleSuffix: t('PantherAi Swap'),
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
        image: pathMetadata.image,
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
